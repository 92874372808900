<template>
  <div class="text-center">
    <v-dialog v-model="showGluedDetailModal">
      <v-card>
        <v-progress-linear
          indeterminate
          color="cyan"
          v-if="queuedLoading"
        ></v-progress-linear>
        <v-card-title class="headline grey lighten-2">
          Ver detalle encolados
        </v-card-title>

        <v-card-text>
          <table>
            <thead>
              <tr>
                <th
                  v-for="(key, k) in columns"
                  :class="{ active: sortKey == key }"
                  :key="k"
                >
                  {{ key.text | capitalize }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(qued, i) in queued" :key="i">
                <!-- <td>
                                {{qued}}
                            </td> -->
                <td v-for="(key, k) in columns" :key="k">
                  <span v-if="key.root != ''">
                    <span v-if="typeof qued[key.root] == 'string'">
                      <span v-if="key.root == 'initTimeStamp'">{{
                        qued[key.root] | formatTime
                      }}</span>
                      <span v-else-if="key.root == 'letter'"
                        >{{ qued[key.root] }} {{ qued.number }}</span
                      >
                      <span v-else>{{ qued[key.root] }}</span>
                    </span>
                    <span
                      v-else-if="
                        typeof qued[key.root][key.sub_root] == 'string'
                      "
                    >
                      <span v-if="key.sub_root == 'firstName'"
                        >{{ qued[key.root][key.sub_root] }}
                        {{ qued[key.root].lastName }}</span
                      >
                      <span v-else>{{ qued[key.root][key.sub_root] }}</span>
                    </span>
                    <span
                      v-else-if="
                        typeof qued[key.root][key.sub_root] == 'object'
                      "
                    >
                      <span
                        v-for="(doc, sk) in qued[key.root][key.sub_root]"
                        :key="sk"
                      >
                        {{ doc.uuidValue }}
                      </span>
                    </span>
                  </span>
                  <!-- <span v-show="qued[key.root] && qued[key.root][key.sub_root]">{{qued[key.root][key.sub_root]}}</span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

        <v-card-actions class="modal-footer">
          <v-btn color="sucess" class="btn-green" @click="close">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "GluedDetail",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    workstationid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showGluedDetailModal: false,
      sortKey: "",
      searchQuery: "",
      columns: [
        { text: "Hora Ingreso", root: "initTimeStamp", sub_root: "" },
        { text: "Turno", root: "letter", sub_root: "" },
        { text: "Tramite", root: "queue", sub_root: "name" },
        // {
        //   text: "Cédula de Identidad",
        //   root: "customer",
        //   sub_root: "customersUuid",
        // },
        { text: "Nombre y Apellido", root: "customer", sub_root: "firstName" },
        { text: "Tags", root: "", sub_root: "" },
        { text: "Tipo de cliente", root: "queue", sub_root: "name" },
      ],
      gridData: [],
      filteredData: [],
    };
  },
  computed: {
    ...mapState({
      queued: (state) => state.queuedSilver.queuedData,
      queuedLoading: (state) => state.queuedSilver.loading,
    }),
  },
  methods: {
    ...mapActions(["fetchQueuedDetails", "resetQueued"]),
    open() {
      this.option = null;
      this.fetchQueuedDetails(
        parseFloat(this.$route.params.workStationId)
      ).then((this.showGluedDetailModal = true));
    },
    async close() {
      this.showGluedDetailModal = false;
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  watch: {
    showGluedDetailModal: function (val) {
      if (!val) {
        this.resetQueued();
      }
    },
  },
};
</script>
<style>
table {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

th {
  font-weight: 600;
  height: 50px;
}
.modal-footer {
  padding: 10px 0px 10px 90% !important;
  border-top: 1px solid #e0e0e0;
}
</style>
